import React, { useState, useEffect } from 'react';
import { PublicKey, Transaction, Connection } from '@solana/web3.js';
import { 
  TOKEN_PROGRAM_ID, 
  getAssociatedTokenAddress, 
  createTransferInstruction,
  getAccount
} from '@solana/spl-token';
import { Buffer } from 'buffer';

// Polyfill Buffer
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}

// Constants
const SOLANA_NETWORK = "https://mainnet.helius-rpc.com/?api-key=1fb263fa-6602-4cee-a7bb-83a22fbe4a52";
const TOKEN_MINT = new PublicKey("EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm");
const TREASURY_WALLET = new PublicKey("DDDDDDMoNHSHprPJKtGK9yeDjtp6VL7QtNqg4DyXMQne");

const App = () => {
  const [isFlipping, setIsFlipping] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [provider, setProvider] = useState(null);
  const [connection, setConnection] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [lastTxSignature, setLastTxSignature] = useState(null);
  const FLIP_COST = 0.01;

  useEffect(() => {
    const conn = new Connection(SOLANA_NETWORK, 'confirmed');
    setConnection(conn);

    const getProvider = () => {
      if ('phantom' in window) {
        const provider = window.phantom?.solana;
        if (provider?.isPhantom) {
          provider.connect({ onlyIfTrusted: true });
          setProvider(provider);
          return provider;
        }
      }
      window.open('https://phantom.app/', '_blank');
    };

    const provider = getProvider();
    if (provider) {
      provider.on('connect', (publicKey) => {
        setWalletAddress(publicKey.toString());
        setError(null);
        fetchTokenBalance(publicKey);
      });
      provider.on('disconnect', () => {
        setWalletAddress('');
        setTokenBalance(0);
      });
      if (provider.isConnected) {
        setWalletAddress(provider.publicKey.toString());
        fetchTokenBalance(provider.publicKey);
      }
    }

    return () => {
      if (provider) {
        provider.disconnect();
      }
    };
  }, []);

  const fetchTokenBalance = async (publicKey) => {
    try {
      const tokenAccount = await getAssociatedTokenAddress(
        TOKEN_MINT,
        publicKey
      );
      
      const account = await getAccount(connection, tokenAccount);
      const balance = Number(account.amount) / (10 ** 6);
      setTokenBalance(balance);
    } catch (err) {
      console.error('Error fetching token balance:', err);
      setTokenBalance(0);
    }
  };

  const connectWallet = async () => {
    try {
      const resp = await provider.connect();
      setWalletAddress(resp.publicKey.toString());
      await fetchTokenBalance(resp.publicKey);
      setError(null);
    } catch (err) {
      setError('Failed to connect wallet: ' + err.message);
    }
  };

  const disconnectWallet = async () => {
    try {
      await provider.disconnect();
      setWalletAddress('');
      setTokenBalance(0);
      setError(null);
    } catch (err) {
      setError('Failed to disconnect wallet: ' + err.message);
    }
  };

  const handleFlip = async () => {
    try {
      if (!provider?.isConnected) {
        throw new Error("Please connect your Phantom wallet first");
      }

      if (tokenBalance < FLIP_COST) {
        throw new Error(`Insufficient balance. You need at least ${FLIP_COST} tokens to play.`);
      }

      setIsFlipping(true);
      setError(null);
      setLastTxSignature(null);

      const ownerTokenAccount = await getAssociatedTokenAddress(
        TOKEN_MINT,
        provider.publicKey
      );

      const treasuryTokenAccount = await getAssociatedTokenAddress(
        TOKEN_MINT,
        TREASURY_WALLET
      );

      const transferInstruction = createTransferInstruction(
        ownerTokenAccount,
        treasuryTokenAccount,
        provider.publicKey,
        FLIP_COST * (10 ** 6)
      );

      const transaction = new Transaction().add(transferInstruction);
      const latestBlockhash = await connection.getLatestBlockhash('confirmed');
      transaction.recentBlockhash = latestBlockhash.blockhash;
      transaction.feePayer = provider.publicKey;

      const signed = await provider.signAndSendTransaction(transaction);
      await connection.confirmTransaction({
        signature: signed.signature,
        blockhash: latestBlockhash.blockhash,
        lastValidBlockHeight: latestBlockhash.lastValidBlockHeight
      });

      setLastTxSignature(signed.signature);
      await fetchTokenBalance(provider.publicKey);

      const randomResult = Math.random() < 0.5 ? 'heads' : 'tails';
      
      setTimeout(() => {
        setResult(randomResult);
        setIsFlipping(false);
      }, 1500);

    } catch (err) {
      setError(err.message);
      setIsFlipping(false);
    }
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 4)}...${address.slice(-4)}`;
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="flex flex-col items-center justify-center space-y-6">
            <div className="w-full flex justify-center mb-4">
              {!walletAddress ? (
                <button
                  onClick={connectWallet}
                  className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded"
                >
                  Connect Phantom Wallet
                </button>
              ) : (
                <div className="flex flex-col items-center space-y-2">
                  <div className="flex items-center space-x-4">
                    <span className="bg-gray-100 rounded-full px-4 py-2 text-sm font-medium">
                      {formatWalletAddress(walletAddress)}
                    </span>
                    <button
                      onClick={disconnectWallet}
                      className="bg-red-500 hover:bg-red-600 text-white text-sm py-2 px-4 rounded"
                    >
                      Disconnect
                    </button>
                  </div>
                  <div className="text-sm font-medium text-gray-600">
                    Balance: {tokenBalance.toFixed(2)} tokens
                  </div>
                </div>
              )}
            </div>

            <div className={`w-32 h-32 rounded-full bg-yellow-400 border-4 border-yellow-600 flex items-center justify-center shadow-lg
              ${isFlipping ? 'animate-[spin_1.5s_ease-in-out]' : ''}`}>
              {!isFlipping && result && (
                <span className="text-2xl font-bold text-yellow-800">
                  {result.toUpperCase()}
                </span>
              )}
            </div>

            <button
              onClick={handleFlip}
              disabled={isFlipping || !walletAddress || tokenBalance < FLIP_COST}
              className={`px-6 py-3 rounded-lg font-semibold text-white shadow-md
                ${(isFlipping || !walletAddress || tokenBalance < FLIP_COST)
                  ? 'bg-gray-400 cursor-not-allowed' 
                  : 'bg-blue-500 hover:bg-blue-600 active:bg-blue-700'
                }`}
            >
              {isFlipping ? 'Flipping...' : `Flip Coin (${FLIP_COST} tokens)`}
            </button>

            {error && (
              <div className="text-red-500 text-sm mt-2">
                {error}
              </div>
            )}

            {result && !isFlipping && (
              <>
                <div className="text-lg font-medium">
                  Result: {result.toUpperCase()}
                </div>
                {lastTxSignature && (
                  <div className="text-sm text-green-600 text-center mt-2">
                    Confirmed! Transaction: 
                    <a 
                      href={`https://solscan.io/tx/${lastTxSignature}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700 ml-1"
                    >
                      {lastTxSignature.slice(0, 4)}...{lastTxSignature.slice(-4)}
                    </a>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;